<template>
<!--  <div class="keji">-->
<!--    <div class="xuqiu_toutiao" style="height: 425px">-->
<!--      <div class="xuqiu">-->
<!--        <div class="xuqiu_header">-->
<!--          <div class="tixing_xuqiu_parent">-->
<!--            <div class="tixing">-->
<!--              企业体检-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <a :href="data.picUrl" target="_blank"><img class="keji_content_left" :src="data&&data.pic"/></a>-->

<!--      </div>-->
<!--      <div class="toutiao">-->
<!--        <div class="toutiao_header">-->
<!--          <div class="tixing_xuqiu_parent">-->
<!--            <div class="tixing" style="width: auto;padding-right: 30px">-->
<!--              {{ data.name }}-->
<!--            </div>-->
<!--            <span style="float: right;display: inline-block;cursor: pointer" @click="goMore">更多>></span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="keji_content_right">-->
<!--          <div class="keji_content_right_per"-->
<!--               v-for="item in data&&data.labels[0].objs.length>7?data.labels[0].objs.slice(0,7):data.labels[0].objs"-->
<!--               :key="item.id">-->
<!--            <router-link :to="{path:'/home/knowledgeDetail',query: {id:item.id}}" style="color: #323232;" target="_blank">-->
<!--              <p><span style="    font-size: 14px;-->
<!--    font-family:'Microsoft YaHei';-->
<!--                               color: rgb(50, 50, 50);width: 300px;display: inline-block;overflow: hidden;text-overflow: ellipsis;white-space: nowrap"><i-->
<!--                class="iconfont iconnews" style="padding-right: 20px"></i>{{item.name}}</span><span-->
<!--                style="float: right;    font-size: 12px;-->
<!--    font-family: 'Microsoft YaHei';-->
<!--                               color: rgb(102, 102, 102);">[{{item.createdTime.split(" ")[0]}}]</span></p>-->
<!--            </router-link>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
  export default {
    name: 'testAndNews',
    props: {
      data: Object
    },
    data() {
      return {}
    },
    methods: {
      goMore() {
        this.$router.push({
          path: '/home/baike',
          query: {
            tab: 3
          }
        })
      }
    }
  }
</script>

<style>

  .keji_content_right_per:last-child {
    border: none;
  }

  .keji_content_right {
    height: 350px;
    width: 480px;
    border: 1px solid #f5f5f5;
  }

  .keji_content_left {
    height: 350px;
    width: 700px;
    object-fit:cover;
    border: 1px solid #f5f5f5;
  }

  .keji {
    width: 1200px;
    margin: 0 auto;
  }
  .keji_content_right_per {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-family: "Microsoft YaHei", serif;
    color: rgb(50, 50, 50);
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    padding: 0 20px;
  }
</style>
